import { useState, useEffect } from 'react';

/**
 * Hook personnalisé pour détecter si l'écran est en mode mobile.
 * @returns {boolean} isMobile - Retourne `true` si l'écran est de largeur ≤ 768px, sinon `false`.
 */
export const useIsMobile = () => {
    if (typeof window !== 'undefined') {
        const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

        useEffect(() => {
            const handleResize = () => setIsMobile(window.innerWidth <= 768);

            window.addEventListener('resize', handleResize);

            handleResize();

            return () => {
            window.removeEventListener('resize', handleResize);
            };
        }, []);

        return isMobile;
    }
};
