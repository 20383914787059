import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export const getPath = (relativePath: string): IGatsbyImageData | null => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  `);

  // Trouve l'image correspondant au `relativePath`
  const correctImage = data.allFile.edges.find(
    (_img: { node: { relativePath: string } }) => _img.node.relativePath.includes(relativePath)
  );


  if (!correctImage || !correctImage.node.childImageSharp) {
    console.error(`Image non trouvée pour le chemin : ${relativePath}`);
    return null;
  }

  // Retourne l'objet `gatsbyImageData` au lieu de l'URL.
  return correctImage.node.childImageSharp.gatsbyImageData;
};
