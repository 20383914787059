import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { css } from '../../../styled-system/css';
import { getPath } from '../../utils/getPath';
import { useIsMobile } from '../../utils/useIsMobile';

interface ResponsiveImageProps {
  imageName?: string;
  relativePath?: string;
  className?: string;
  wrapperClassName?: string;
  background?: string;
  imageAlt?: string;
  customStyle?: any;
}

// Higher Order Component pour conditionner le wrapper
const withWrapper = (Component: React.FC<ResponsiveImageProps>) => {
  
  return (props: ResponsiveImageProps) => {
    const { wrapperClassName, background } = props;

    // Si `wrapperClassName` est défini, entoure le composant d'une <div>
    if (wrapperClassName) {
      return (
        <div
          className={`${wrapperClassName} ${css({
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: -1,
            background: background ? background : 'inherit',
          })}`}
        >
          <Component {...props} />
        </div>
      );
    }

    // Sinon, retourne le composant d'origine
    return <Component {...props} />;
  };
};

// Composant de base `GatsbyImageComponent`
const GatsbyImageComponent: React.FC<ResponsiveImageProps> = ({
  imageName,
  relativePath,
  className,
  imageAlt,
  customStyle = {}
}: ResponsiveImageProps) => {

  const isMobile = useIsMobile();

  // Récupère les données d'image Gatsby (gatsbyImageData)
  const imageData: IGatsbyImageData | null = relativePath ? getPath(relativePath) : imageName ? getPath(imageName) : null;

  if (!imageData) {
    return null;
  }

  return (
    <GatsbyImage
      className={className ? className : ''}
      image={imageData}
      alt={imageAlt ?? ''}
      style={Object.assign({
        width: '100%',
        height: '100%'
      }, customStyle)}
      objectFit={isMobile ? 'contain' : 'cover'} 
      objectPosition="center"
    />
  );
};

// Application du HOC `withWrapper` pour créer `ResponsiveImage`
const ResponsiveImage = withWrapper(GatsbyImageComponent);

export default ResponsiveImage;
